/**
 * @by dengs
 * 将常用复用的组件导出 全局注册
 * 直接使用 无需引入和注册
 */

import GPagination from './Pagination/index'
import GDialog from './Dialog/index'
import GBreadcrumb from './Breadcrumb/index'
import GCascader from './Cascader/index'
import GSelect from './Select/index'
import Gbutton from './Button/index'
import Ginput from './Input/index'
import Gtextarea from './Textarea/index'
import SvgIcon from './SvgIcon/index'

// 界面样式组件
// 检索条件容器
import SearchForm from './businessComponents/SearchForm'
// 表格容器 带标题，带按钮插槽
import TableContainer from './businessComponents/TableContainer'
// 带tab切换的表格容器，带按钮插槽
import TabsContainerBar from './businessComponents/TabsContainerBar'
// 全屏弹窗 容器组件
import popup from './Popup/index'
// 伸缩 内容 容器
import infoUpDown from './businessComponents/infoUpDown'

const comps = {
  GPagination,
  GDialog,
  GBreadcrumb,
  GCascader,
  GSelect,
  Gbutton,
  Ginput,
  Gtextarea,
  SvgIcon,
  SearchForm,
  TableContainer,
  TabsContainerBar,
  popup,
  infoUpDown
}

// 注册全局组件并暴露出去
function regComp(Vue) {
  if (regComp.installed) {
    return
  }
  Object.keys(comps).forEach(key => {
    Vue.component(key, comps[key])
  })
}

// 自动导入 svg 源码
const req = require.context('@assets/svgs', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

export default regComp
