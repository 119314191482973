<template>
  <div class="table-container">
    <slot name="topContent" />
    <div class="tit-bar">
      <h3>{{ title }}</h3>
      <div class="button-bar">
        <slot name="button" />
      </div>
    </div>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '列表'
    }
  }
}
</script>
<style lang="scss">
  .table-container {
    padding: 24px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(82, 80, 75, 0.08);
    h3{color: #53402B;;}
    .tit-bar{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
  }
</style>
