import router from './router'
// import store from '@/store/store.js'

router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/login')) {
    window.localStorage.removeItem('token')
    next()
  } else {
    const token = window.localStorage.getItem('token')
    // store.dispatch('systemUserGetMenu', store.state)
    if (!token) {
      next({ path: '/login' })
    } else {
      next()
    }
  }
})
