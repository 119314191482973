import { getCurriculumType } from '@api/courseManagement/api_courseManagement'
const courseStep = {
  namespaced: true,
  state: {
    addOrEdit: 'add',
    courseData: {},
    courseId: '',
    currentClickData: {},
    stepOptions: [],
    stepActive: 0,

    // step步骤 选项
    wordsOptions: [],
    highFrequencyWordOptions: [],
    sentenceOptions: [],
    sentenceGroupOptions: [],
    naturalSpellingOptions: [],

    courseStepOptions: {
      // 单词
      WORDS: {
        'Lead-in': { dicName: 'Lead-in', step: 'step1', checkedType: ['WORD_MEANS'], name: '单词' },
        'Highlights': { dicName: 'Highlights', step: 'step2', checkedType: ['WORD_MEANS'], name: '单词' },
        'Story': { dicName: 'Story', step: 'step3', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Speaking': { dicName: 'Speaking', step: 'step4', checkedType: ['WORD_MEANS', 'SENTENCE_PATTERN_MEANS'], name: '单词' },
        'Quiz': { dicName: 'Quiz', step: 'step5', checkedType: ['WORD_MEANS'], name: '单词' }
      },
      // 高频词
      SIGHTWORDS: {
        'Lead-in': { dicName: 'Lead-in', step: 'step1', checkedType: ['HIGH_FREQUENCY_MEANS'], name: '高频词' },
        'Highlights': { dicName: 'Highlights', step: 'step2', checkedType: ['HIGH_FREQUENCY_MEANS'], name: '高频词' },
        'Story': { dicName: 'Story', step: 'step3', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Speaking': { dicName: 'Speaking', step: 'step4', checkedType: ['HIGH_FREQUENCY_SENTENCE_PATTERN_MEANS'], name: '高频词' },
        'Quiz': { dicName: 'Quiz', step: 'step5', checkedType: ['HIGH_FREQUENCY_SENTENCE_PATTERN_MEANS'], name: '高频词' }
      },
      // 阅读
      READING: {
        'Lead-in': { dicName: 'Lead-in', step: 'step1', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Highlights': { dicName: 'Highlights', step: 'step2', checkedType: ['HIGH_FREQUENCY_MEANS', 'NATURAL_SPELLING_MEANS'], name: '高频词' },
        'Story': { dicName: 'Story', step: 'step3', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Speaking': { dicName: 'Speaking', step: 'step4', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Quiz': { dicName: 'Quiz', step: 'step5', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' }
      },
      // 自然拼读
      PHONICS: {
        'Lead-in': { dicName: 'Lead-in', step: 'step1', checkedType: ['NATURAL_SPELLING_MEANS'], name: '自然拼读(字母)' },
        'Highlights': { dicName: 'Highlights', step: 'step2', checkedType: ['NATURAL_SPELLING_MEANS'], name: '自然拼读(字母)' },
        'Story': { dicName: 'Story', step: 'step3', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Speaking': { dicName: 'Speaking', step: 'step4', checkedType: ['NATURAL_SPELLING_MEANS'], name: '自然拼读(字母)' },
        'Quiz': { dicName: 'Quiz', step: 'step5', checkedType: ['NATURAL_SPELLING_MEANS'], name: '自然拼读(字母)' }
      },
      // 句型
      SENTENCE: {
        'Lead-in': { dicName: 'Lead-in', step: 'step1', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Highlights': { dicName: 'Highlights', step: 'step2', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Story': { dicName: 'Story', step: 'step3', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Speaking': { dicName: 'Speaking', step: 'step4', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Quiz': { dicName: 'Quiz', step: 'step5', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' }
      },

      // 复习课
      // 单词 复习课
      REVIEWWORDS: {
        'Lead-in': { dicName: 'Lead-in', step: 'step1', checkedType: ['WORD_MEANS'], name: '单词' },
        'Highlights': { dicName: 'Highlights', step: 'step2', checkedType: ['WORD_MEANS'], name: '单词' },
        'Quiz': { dicName: 'Quiz', step: 'step5', checkedType: ['WORD_MEANS'], name: '单词' }
      },
      // 高频词 复习课
      REVIEWSIGHTWORDS: {
        'Lead-in': { dicName: 'Lead-in', step: 'step1', checkedType: ['HIGH_FREQUENCY_MEANS'], name: '高频词' },
        'Highlights': { dicName: 'Highlights', step: 'step2', checkedType: ['HIGH_FREQUENCY_MEANS'], name: '高频词' },
        'Quiz': { dicName: 'Quiz', step: 'step5', checkedType: ['HIGH_FREQUENCY_SENTENCE_PATTERN_MEANS'], name: '高频词' }
      },
      // 阅读 复习课
      REVIEWREADING: {
        'Lead-in': { dicName: 'Lead-in', step: 'step1', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Highlights': { dicName: 'Highlights', step: 'step2', checkedType: ['HIGH_FREQUENCY_MEANS', 'NATURAL_SPELLING_MEANS'], name: '高频词' },
        'Quiz': { dicName: 'Quiz', step: 'step5', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' }
      },
      // 自然拼读 复习课
      REVIEWPHONICS: {
        'Lead-in': { dicName: 'Lead-in', step: 'step1', checkedType: ['NATURAL_SPELLING_MEANS'], name: '自然拼读(字母)' },
        'Highlights': { dicName: 'Highlights', step: 'step2', checkedType: ['NATURAL_SPELLING_MEANS'], name: '自然拼读(字母)' },
        'Quiz': { dicName: 'Quiz', step: 'step5', checkedType: ['NATURAL_SPELLING_MEANS'], name: '自然拼读(字母)' }
      },
      // 句型 复习课
      REVIEWSENTENCE: {
        'Lead-in': { dicName: 'Lead-in', step: 'step1', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Highlights': { dicName: 'Highlights', step: 'step2', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' },
        'Quiz': { dicName: 'Quiz', step: 'step5', checkedType: ['SENTENCE_PATTERN_GROUP_MEANS'], name: '句型组' }
      }
    }

  },
  getters: {

  },
  mutations: {
    SET_addOrEdit (state, addOrEdit) {
      console.log(addOrEdit)
      state.addOrEdit = addOrEdit
    },
    SET_currentClickData (state, currentClickData) {
      state.currentClickData = currentClickData
    },
    SET_courseData (state, courseData) {
      state.courseData = courseData
    },
    SET_courseId (state, courseId) {
      state.courseId = courseId
    },
    SET_stepOptions (state, stepOptions) {
      state.stepOptions = stepOptions
    },
    SET_stepActive (state, stepActive) {
      state.stepActive = stepActive
    }
  },
  actions: {
    getCurriculumType ({ commit, state }, meansType) {
      return new Promise((resolve, reject) => {
        const params = {
          themeIds: state.courseData.themeIds,
          meansType
        }
        getCurriculumType(params).then(res => {
          resolve(res)
        })
      })
    }
  }
}

export default courseStep
