<template>
  <!-- :style="className" -->
  <div v-show="show" id="popup_wrap">
    <div class="backbtn">
      <GBreadcrumb :bread="bread" />
      <div class="back">
        <slot name="button" />
        <el-button style="margin-left: 12px;" type="primary" @click="onBack">返 回</el-button>
      </div>
      <slot name="self" />
    </div>
    <div class="popup_content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bread: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }

  },
  computed: {
    breadList () {
      if (this.bread) {
        return this.bread.split(',')
      } else {
        return []
      }
    }
    // className () {
    //   if (this.$store.state.collapsed) {
    //     return { left: '64px' }
    //   }
    // }
  },
  methods: {
    onBack () {
      this.$emit('onBack')
    }
  }
}
</script>

<style lang="scss">
  #popup_wrap{
    position: fixed;top: 80px;left: 200px;right: 0;bottom: 0;background-color: #F7F6F4;padding: 0 24px 50px 24px;z-index: 999;overflow-y: auto;transition: all 0.5s;
    // @media screen and (max-width: 1442px) {
    //   top: 70px;left: 170px;
    // }
    .common-breadcrumb{margin-bottom: 0;}
    .backbtn{display: flex;justify-content: space-between;align-items: center;margin:13px 0;}
  }
</style>
