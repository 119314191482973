<template>
  <el-dialog
    :title="dialog.title || '提示'"
    :visible.sync="isShow"
    :width="width"
    :top="top"
    :fullscreen="fullscreen"
    :append-to-body="appendToBody"
    :close-on-click-modal="closeClickModal"
    :center="center"
    :close-on-press-escape="false"
    :show-close="showCloseBtn"
    :before-close="handleClose"
    class="overallStyle"
  >
    <slot />
    <div v-if="showFooter" slot="footer" class="dialog-footer">
      <!-- 按钮左边的内容 -->
      <slot name="footerSlot" />
      <el-button v-if="defaultBtn" type="primary" @click="handleBtnSure">{{ btnSure }}</el-button>
      <el-button type="info" @click="handleClose">{{ btnCancle }}</el-button>
      <!-- 按钮右边的内容 -->
      <slot name="footerBtn" />
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '50%'
    },
    top: {
      type: String,
      default: '15vh'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    closeClickModal: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    showCloseBtn: {
      type: Boolean,
      default: true
    },
    // 是否隐藏底部
    showFooter: {
      type: Boolean,
      default: true
    },
    // dialog取消按钮
    btnCancle: {
      type: String,
      default: '取 消'
    },
    // dialog确认按钮
    btnSure: {
      type: String,
      default: '确 定'
    },
    defaultBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    isShow: {
      get() {
        return this.dialog.visible
      },
      set(val) {
        const dialog = this.dialog
        dialog.visible = val
        this.$emit('update: dialog', dialog)
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('btnClose')
      this.isShow = false
    },
    handleBtnSure() {
      this.$emit('btnSure')
    }
  }
}
</script>

<style lang="scss">
.overallStyle{
  .el-dialog{box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);border-radius: 12px;}
  .el-dialog__title{font-size: 16px;color: #53402B;}
  .el-dialog__header{padding: 18px 24px;}
  .el-dialog__body{padding: 14px 32px 0 32px;}
  .el-dialog__footer{
    background: #FAFAFA;border-radius: 0px 0px 12px 12px;padding: 16px 0;text-align: center;
  }
}
</style>
