<template>
  <el-select
    ref="innerSelect"
    style="width:100%"
    :value="modelVal"
    value-key="value"
    :disabled="disabled"
    :clearable="clearable"
    :multiple="multiple"
    :collapse-tags="multiple"
    :filterable="filterable"
    :placeholder="placeholder"
    :multiple-limit="limit"
    @change="handleChange"
  >
    <!-- 分组 -->
    <template v-if="isGroup">
      <el-option-group v-for="group in options" :key="group.label" :label="group.label">
        <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="isValObj ? item : item.value"><slot name="selectCon" /></el-option>
      </el-option-group>
    </template>
    <!-- 不分组 -->
    <template v-else>
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="isValObj ? item : item.value"><slot name="selectCon" /></el-option>
    </template>
  </el-select>
</template>

<script>
export default {
  model: {
    prop: 'modelVal',
    event: 'modifyChange'
  },
  props: {
    modelVal: {
      type: [Number, Array, String, Object],
      default: null
    },
    options: {
      type: Array,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    // 是否分组
    isGroup: {
      type: Boolean,
      default: false
    },
    // 是否绑定值为对象
    isValObj: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      // 仅适用于单选
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  methods: {
    handleChange(val) {
      this.$emit('modifyChange', val) // 将原change事件暴露
    }
  }
}
</script>

<style lang="scss" scoped></style>
