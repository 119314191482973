<template>
  <svg :class="svgClass" aria-hidden="true" :style="{width: size,height:size}">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
import '@assets/iconfont.js'
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '14px'
    }
  },
  computed: {
    iconName() {
      return `#${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    }
  }
}
</script>

<style scoped>
.svg-icon {
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
</style>
