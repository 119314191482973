<template>
  <div class="personalContainer">
    <p v-if="isShowTitle">{{ bread }}
      <!-- <el-button v-if="showIcon" type="primary" :icon="isShow?'el-icon-arrow-up':'el-icon-arrow-down'" circle size="mini" @click="isShow = !isShow" /> -->
    </p>
    <el-collapse-transition>
      <div v-show="isShow" class="paddlr">
        <slot />
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  props: {
    bread: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    isShowTitle: { // 是否需要展示标题
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isShow: true
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
  .personalContainer {
   box-shadow: 0px 4px 10px rgba(82, 80, 75, 0.08);
    border-radius: 12px;
    background: white;
    margin-bottom: 20px;
    padding:0 24px 24px 24px;
    .el-form-item__label {color: #898884;}
    // .paddlr {padding: 30px;}
    // .el-col {
    //   border-bottom: 1px solid rgba(0,0,0,0);
    //   border-collapse: collapse;
    //   color: #606266;
    //   font-size: 14px;
    //   padding-left: 10px;
    //   &.el-col-8,&.el-col-12 {
    //     .el-form-item__label {width: 100px;padding: 0;}
    //     .el-form-item__content {width: 60%;display: inline-block;}
    //     .el-date-editor.el-input {width: 100%;}
    //     .el-select {width: 100%;}
    //   };
    //   &.el-col-4 {position: relative;}
    // }
    &>p {
      height: 56px;
      line-height: 56px;
      font-size: 16px;
      color: #53402B;
      position: relative;
      border-bottom: 1px solid #EEEEEE;
      margin-bottom: 10px;
      .el-button--mini.is-circle{
        position: absolute;
        top: 10px;
        right: 20px;
      }
    }
  }
</style>
