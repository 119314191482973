<template>
    <div class="tabsContainerBar">
      <div class="buttonWrap">
        <slot name="button"></slot>
      </div>
      <slot></slot>
    </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
  .tabsContainerBar {
    position: relative;margin-top: 30px;
    .buttonWrap {
      position: absolute;right: 10px;top: 3px;font-size: 14px;z-index: 1;
    }
    .el-tabs--card > .el-tabs__header {
      margin: 0 0 0 30px;border-bottom: none;
      .el-tabs__nav {
        border: none;
        .el-tabs__item {
        box-shadow: 0px 3px 10px 0px rgba(63, 187, 242, 0.35);
        border: none;border-radius: 20px 20px 0 0;background: #3fbbf2;color: white;min-width: 120px;text-align: center;
        &.is-active {
          color: #3fbbf2;background: white;
          }
        }
      }
    }
  }
</style>
