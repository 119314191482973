<template>
  <!--导航菜单-->
  <el-menu ref="leftNavigation" router :collapse="collapsed" :default-active="$route.path" class="navBox" unique-opened>
    <template v-for="item in leftNavList">
      <el-submenu v-if="item.children.length > 0 && item.children[0].menuType !== 3" :key="item.id" :index="item.id.toString()" :show-timeout="100">
        <template slot="title" class="title">
          <!-- <SvgIcon :icon-class="item.menuPicCode" size="16px" /> -->
          <span>{{ item.menuName }}</span>
        </template>
        <el-menu-item
          v-for="term in item.children"
          :key="term.id"
          :index="term.menuUrl"
        >
          <span slot="title" class="title">{{ term.menuName }}</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        v-else
        :key="item.id"
        :index="item.menuUrl"
      >
		<!-- <img :src="item.menuPicCode" style="width: 16px;height: 16px;"/> -->
        <SvgIcon :icon-class="item.menuPicCode" size="16px" />
        <span slot="title" class="title">{{ item.menuName }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script>
// import { setTimeout } from 'timers'
export default {
  name: 'LeftNav',
  props: {
    leftNavList: {
      type: Array,
      default: null
    },
    collapsed: {
      type: Boolean
    }
  },
  // beforeRouteUpdate (to, from, next) {
  //   console.log(1234556)
  //   console.log(to)
  // },
  data () {
    return {

    }
  },

  mounted () {

  },
  methods: {

  }
}
</script>
<style scoped lang="scss">
	.title{
		padding-left: 4px;
	}
</style>
