<template>
  <div class="search-bar">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
  .search-bar {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(82, 80, 75, 0.08);
    opacity: 0.9;
    border-radius: 12px;
    // padding: 20px 24px 0px 24px;
    margin-bottom: 20px;
    .el-form-item{margin-bottom: 20px;}
  }
</style>
