import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import commonModule from './modules/commonModule.js'
import courseStep from './modules/courseStep.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    employeeInfo: {},
    // 对于 401错误 多次弹出，加一个开关进行控制
    isShowErrorMessageBox: true,
    token: localStorage.getItem('token') || ''
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    SET_USERINFO (state, employeeInfo) {
      state.employeeInfo = employeeInfo
    },
    SET_isShowErrorMessageBox (state, flag) {
      state.isShowErrorMessageBox = flag
    }

  },
  actions: {
    // 注销
    LogOut () {
      return new Promise((resolve, reject) => {
        localStorage.removeItem('token')
        router.push('/login')
        resolve()
      })
    }
  },
  modules: {
    commonModule,
    courseStep
  }
})
