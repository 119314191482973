import request from '@utils/fetch'

// 查询机构列表，type集团：1，片区：2，园所：3
export function getOrgs(data) {
  return request({
    url: '/organization/getOrgs',
    method: 'post',
    data
  })
}

// 查询组织机构树，type：1/2 级别到片区，3到园所
export function getOrgGroupScaleRes(data) {
  return request({
    url: '/organization/getOrgGroupScaleRes',
    method: 'post',
    params: data
  })
}

// 文件上传 公共接口
export function uploadFile(data) {
  return request({
    url: '/common/uploadFile',
    method: 'post',
    data
  })
}

// 字典查询接口
export function getDictoryInfoByCode(data) {
  return request({
    url: '/common/getDictoryInfoByCode',
    method: 'post',
    params: { dicCode: data }
  })
}

