import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './permission.js'
import '@assets/scss/reset.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@assets/scss/index.css'
import '@assets/scss/element-variables.scss'
import '@assets/scss/_elementReset.scss'
import '@assets/scss/_common.scss'
// 全局组件引用
import globalComps from '@components/globalComps'
import VueJsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', VueJsonExcel);

Vue.use(globalComps)
Vue.use(ElementUI)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
