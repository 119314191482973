<template>
	<el-container class="layout-container">
		<!-- 侧边栏 -->
		<aside class="left-aside">
			<img src="@/assets/images/img12.jpg">
			<left-nav :left-nav-list="leftNavList" />
		</aside>
		<el-container class="right-wrap">
			<el-header height="80px">
				<el-menu :default-active="firstLevelMenuId" class="nav" mode="horizontal"
					@select="firstLevelMenuSelect">
					<template v-for="item in allMenuData">
						<el-menu-item :key="item.id" :index="item.id.toString()">
							<div class="icon-bar">
								<!-- <img :src="item.menuPicCode" style="width: 22px;height: 22px;"/> -->
								<SvgIcon :icon-class="item.menuPicCode" size="22px" />
							</div>
							<span>{{ item.menuName }}</span>
							<SvgIcon icon-class="smile" size="20px" class="smile" />
						</el-menu-item>
					</template>
				</el-menu>
				<div class="user-bar">
					<SvgIcon icon-class="man" size="24px" />
					<h4>用户名</h4>
					<span />
					<SvgIcon class="exit" icon-class="exit" size="18px" @click.native="signOut" />
				</div>
			</el-header>
			<el-main id="mainContent" class="rightMain" style="background-color: #ffffff;">
				<transition name="fade" mode="out-in">
					<router-view />
				</transition>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import leftNav from '@components/leftNav/leftNav.vue'
	import {
		getMean
	} from '@api/administratorAction/api_administratorAction';

	export default {
		name: 'Home',
		components: {
			leftNav
		},
		data() {
			return {
				firstLevelMenuId: '1',
				allMenuData: [],
				leftNavList: [],
				collapsed: false
			}
		},
		computed: {

		},
		created() {
			// this.allMenuData = menuData.data.systemMenu
			this.getMean()
			this.getCurrentleftNavList()
			// 默认跳转至 第一个一级菜单的子菜单
			// this.firstLevelMenuSelect(this.firstLevelMenuId)
		},
		methods: {
			getMean(){
				let params = {
					type: 0
				}
				getMean(params).then((res) =>{
					this.allMenuData = res
					this.getCurrentleftNavList()
					// console.log(this.allMenuData,1111)
					if(this.allMenuData.length == 0){
						// console.log(111)
						 this.$router.push('/gardenManagement')
					}
				})
			},
			menuToggle() {
				this.collapsed = !this.collapsed
				this.$store.commit('SET_COLLAPSED', this.collapsed)
			},
			firstLevelMenuSelect(index) {
				this.firstLevelMenuId = index
				// 当前一级菜单对象
				let currentItem
				for (const item of this.allMenuData) {
					if (index === item.id.toString()) {
						this.leftNavList = item.children
						currentItem = item
						break
					}
				}
				const url = this.getMenuUrl(currentItem)
				this.$router.push({
					path: url
				})
			},
			// 递归 查询 子级第一个 子菜单
			getMenuUrl(data) {
				let url = null
				if (url) {
					return url
				}
				if (data.menuType === 1 && data.children.length > 0) {
					url = this.getMenuUrl(data.children[0])
				} else if (data.menuType === 2) {
					url = data.menuUrl
				}
				return url
			},
			// 情形一 刷新时，根据当前路由，获取到左侧菜单的 集合
			// 情形二 其他页面点击跳转路由，点亮菜单
			getCurrentleftNavList() {
				const currentRoute = this.allMenuData.filter((item) => {
					return item.menuUrl === ('/' + this.$route.path.split('/')[1])
				})
				this.leftNavList = currentRoute[0] ? currentRoute[0].children : [],
				this.firstLevelMenuId = currentRoute[0] ? currentRoute[0].id.toString() : ''
			},
			signOut() {
				this.$confirm('确认退出吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.dispatch('LogOut')
				})
			}
		}
	}
</script>
