import request from '@utils/fetch'

// 上移主题接口
export function moveUpTheme(themeId) {
  return request({
    url: '/taskManage/moveUpTheme',
    method: 'post',
    params: { themeId: themeId }
  })
}

// 下移主题接口
export function moveDownTheme(themeId) {
  return request({
    url: '/taskManage/moveDownTheme',
    method: 'post',
    params: { themeId: themeId }
  })
}

// 上移课程接口
export function moveUpCurriculum(themeId, curriculumId) {
  return request({
    url: '/taskManage/moveUpCurriculum',
    method: 'post',
    params: {
      themeId: themeId,
      curriculumId: curriculumId
    }
  })
}

// 下移课程接口
export function moveDownCurriculum(themeId, curriculumId) {
  return request({
    url: '/taskManage/moveDownCurriculum',
    method: 'post',
    params: {
      themeId: themeId,
      curriculumId: curriculumId
    }
  })
}

// 教材 列表接口
export function getMaterialPageList(data) {
  return request({
    url: '/teachingMaterial/getMaterialPageList',
    method: 'post',
    data
  })
}

// 更新教材
export function updateTeaching(data) {
  return request({
    url: '/taskManage/updateTeaching',
    method: 'post',
    data
  })
}

// 课程/任务 列表接口
export function selectTaskManageList(data) {
  return request({
    url: '/taskManage/selectTaskManageList',
    method: 'post',
    data
  })
}

// 添加 主题
export function addTheme(data) {
  return request({
    url: '/taskManage/addTheme',
    method: 'post',
    data
  })
}

// 编辑 主题
export function updateTheme(data) {
  return request({
    url: '/taskManage/updateTheme',
    method: 'post',
    data
  })
}

// 删除 主题
export function deleteTheme(data) {
  return request({
    url: '/taskManage/deleteTheme',
    method: 'delete',
    params: data
  })
}

// 添加课程接口
export function addCurriculum(data) {
  return request({
    url: '/curriculumManage/addCurriculum',
    method: 'post',
    data
  })
}

// 编辑课程接口
export function updateCurriculum(data) {
  return request({
    url: '/curriculumManage/updateCurriculum',
    method: 'post',
    data
  })
}

// 删除课程接口
export function deleteCurriculum(data) {
  return request({
    url: '/taskManage/deleteCurriculum',
    method: 'delete',
    params: data
  })
}

// 编辑时，查询课程详细信息
export function getCurriculumInfo(data) {
  return request({
    url: '/curriculumManage/getCurriculumInfo',
    method: 'post',
    params: data
  })
}

// 添加课程 查询教材与主题资料树结构。
export function getTeachingAndThemeList(data) {
  return request({
    url: '/curriculumManage/getTeachingAndThemeList',
    method: 'post',
    data
  })
}

// 添加或更新阶段任务接口
export function addStageCurriculumConf(data) {
  return request({
    url: '/curriculumManage/addStageCurriculumConf',
    method: 'post',
    data
  })
}

// 查询 step 步骤 选项源 数据
export function getCurriculumType(data) {
  return request({
    url: '/curriculumManage/getCurriculumType',
    method: 'post',
    data
  })
}

// 查询 step 步骤 已选中 数据
export function getStepData(data) {
  return request({
    url: '/curriculumManage/getStepData',
    method: 'post',
    data
  })
}

// 所有步骤 课程的提交接口
export function addCurriculumSubmit(data) {
  return request({
    url: '/curriculumManage/addCurriculumSubmit',
    method: 'post',
    data
  })
}
