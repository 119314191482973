<template>
  <el-input
    type="text"
    :value="inputVal"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :show-password="showPsw"
    :suffix-icon="suffixIcon"
    :prefix-icon="prefixIcon"
    :readonly="readonly"
    :maxlength="maxlength"
    :minlength="minlength"
    :min="min"
    :max="max"
    :step="step"
    @input="handleInput"
  >
    <template v-if="prepend" slot="prepend">
      {{ prepend }}
    </template>
    <template v-if="append" slot="append">
      {{ append }}
    </template>
  </el-input>
</template>

<script>
export default {
  model: {
    prop: 'inputVal',
    event: 'modifyChange'
  },
  props: {
    inputVal: [String, Number],
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    showPsw: {
      type: Boolean,
      default: false
    },
    suffixIcon: {
      type: String,
      default: ''
    },
    prefixIcon: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 1000
    },
    minlength: {
      type: Number,
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 0
    },
    prepend: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleInput($event) {
      this.$emit('modifyChange', $event)
    }
  }
}
</script>
