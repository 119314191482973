<template>
  <div>
    <el-button :type="btype" :plain="plain" :round="round" :circle="circle" :disabled="disabled" :icon="icon" :loading="loading" @click="handleClick">
      {{ bText }}
      <!-- 插槽 -->
      <slot name="buttonCon" />
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    btype: {
      type: String,
      default: ''
    },
    bText: {
      type: String,
      default: ''
    },
    plain: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      this.$emit('btnClick')
    }
  }
}
</script>
