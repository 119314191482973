import request from '@utils/fetch'

//查询菜单树结构
export function getMean(data) {
  return request({
    url: '/organization/getMean',
    method: 'post',
    data
  })
}

//新增角色权限
export function addRule(data) {
  return request({
    url: 'organization/addRule',
    method: 'post',
    data
  })
}

//查询角色列表
export function selectRule(data) {
  return request({
    url: 'organization/selectRule',
    method: 'post',
    data
  })
}

//编辑角色
export function updateRule(data) {
  return request({
    url: 'organization/updateRule',
    method: 'post',
    data
  })
}

//查询用户权限表
export function findUser(data) {
  return request({
    url: '/systemUser/findUser',
    method: 'post',
    data
  })
}

//新增用户权限
export function addUser(data) {
  return request({
    url: '/systemUser/addUser',
    method: 'post',
    data
  })
}

//编辑用户权限
export function editUser(data) {
  return request({
    url: '/systemUser/editUser',
    method: 'post',
    data
  })
}

//删除用户
export function delUser(data) {
  return request({
    url: '/systemUser/delUser',
    method: 'post',
    data
  })
}