import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@views/Home.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/gardenManagement',
    name: '',
    component: Home,
    children: [
      {
        path: '/gardenManagement',
        name: '订单管理',
        component: () => import('@/views/gardenManagement/index.vue')
      },
	  {
	    path: '/gardenManagement/wangDianTong',
	    name: '旺店通',
	    component: () => import('@/views/gardenManagement/wangDianTong/index.vue')
	  },
	  {
	    path: '/gardenManagement/userOrder',
	    name: '客户订单明细',
	    component: () => import('@/views/gardenManagement/userOrder/index.vue')
	  },
	  {
	    path: '/gardenManagement/general',
	    name: '总部订单明细',
	    component: () => import('@/views/gardenManagement/general/index.vue')
	  },
	  {
	    path: '/gardenManagement/enterOrder',
	    name: '订单录入管理',
	    component: () => import('@/views/gardenManagement/enterOrder/index.vue')
	  },
	  {
	    path: '/gardenManagement/notUpdate',
	    name: '未更新订单管理',
	    component: () => import('@/views/gardenManagement/notUpdate/index.vue')
	  },
	  {
	    path: '/gardenManagement/beReviewed',
	    name: '待审核订单管理',
	    component: () => import('@/views/gardenManagement/beReviewed/index.vue')
	  },
    {
	    path: '/gardenManagement/anchorIn',
	    name: '录入订单数量',
	    component: () => import('@/views/gardenManagement/anchorIn/index.vue')
	  },
	  {
	    path: '/gardenManagement/enterTbOrderGoods',
	    name: '录入淘系订单商品管理',
	    component: () => import('@/views/gardenManagement/enterTbOrderGoods/index.vue')
	  },
	  {
	    path: '/gardenManagement/enterTbOrder',
	    name: '待审核订单管理',
	    component: () => import('@/views/gardenManagement/enterTbOrder/index.vue')
	  },
    ]
  },
  {
    path: '/tbManagement',
    name: '淘系录入',
    component: Home,
    children: [
      {
        path: '/tbManagement/enterTbOrder',
        name: '淘系录入',
        component: () => import('@/views/tbManagement/enterTbOrder/index.vue')
      },
	  {
	    path: '/tbManagement/enterTbOrderGoods',
	    name: '淘系商品录入',
	    component: () => import('@/views/tbManagement/enterTbOrderGoods/index.vue')
	  }
    ]
  },
  {
    path: '/agentManagement',
    name: '',
    component: Home,
    children: [
      {
        path: '/agentManagement',
        name: '代理商管理',
        component: () => import('@/views/agentManagement/index.vue')
      }
    ]
  },
  {
    path: '/doctorManagement',
    name: '',
    component: Home,
    children: [
      {
        path: '/doctorManagement',
        name: '客户管理',
        component: () => import('@/views/doctorManagement/index.vue')
      },
	  {
		 path: '/doctorManagement/doctorOrder',
		 name: '客户管理',
		 component: () => import('@/views/doctorManagement/doctorOrder/index.vue') 
	  }
    ]
  },{
    path: '/bxPartyMenu',
    name: '',
    component: Home,
    children: [
      {
        path: '/bxPartyMenu/bxPartyUser',
        name: '活动管理',
        component: () => import('@/views/bxPartyMenu/bxPartyUser.vue')
      }, {
        path: '/bxPartyMenu/bxMinPartyUser',
        name: '活动管理',
        component: () => import('@/views/bxPartyMenu/bxMinPartyUser.vue')
      }
      
    ]
  },
  {
    path: '/bxMgMenu',
    name: '',
    component: Home,
    children: [
      {
        path: '/bxMgMenu/bxMgList',
        name: '"玫瑰痤疮管理',
        component: () => import('@/views/bxMgMenu/bxMgList.vue')
      },
      
    ]
  },
  {
    path: '/administratorAction',
    name: '',
    component: Home,
    children: [
      {
        path: '/administratorAction',
        name: '管理员操作',
        component: () => import('@/views/administratorAction/index.vue')
      },
	  {
	    path: '/administratorAction/roleList',
	    name: '角色列表',
	    component: () => import('@/views/administratorAction/roleList/index.vue')
	  },
	  {
	    path: '/administratorAction/userManagement',
	    name: '用户管理',
	    component: () => import('@/views/administratorAction/userManagement/index.vue')
	  },
	  
    ]
  },
  {
    path: '/classManagement',
    name: '',
    component: Home,
    children: [
      {
        path: '/classManagement',
        name: '商品管理',
        component: () => import('@/views/classManagement/index.vue')
      }
    ]
  },
  {
    path: '/teachBookManagement',
    name: '用户管理',
    component: Home,
    children: [
      {
        path: '/teachBookManagement/courseManagement',
        name: '小程序用户',
        component: () => import('@/views/teachBookManagement/courseManagement/index.vue')
      },
	  {
	    path: '/teachBookManagement/userMfManagement',
	    name: '敏肤用户',
	    component: () => import('@/views/teachBookManagement/userMfManagement/index.vue')
	  },
	  {
	    path: '/teachBookManagement/userOldManagement',
	    name: '系统用户',
	    component: () => import('@/views/teachBookManagement/userOldManagement/index.vue')
	  },
	  {
	    path: '/teachBookManagement/userSystemManagement',
	    name: '系统用户',
	    component: () => import('@/views/teachBookManagement/userSystemManagement/index.vue')
	  }
    ]
  },
  {
    path: '/wenxinMenu',
    name: '公众号配置',
    component: Home,
    children: [
      {
        path: '/wenxinMenu/menuConf',
        name: '菜单配置',
        component: () => import('@/views/wenxinMenu/menuConf/index.vue')
      },
  	  {
  	    path: '/wenxinMenu/messageRich',
  	    name: '跳转富文本',
  	    component: () => import('@/views/wenxinMenu/messageRich/index.vue')
  	  },
  	  {
  	    path: '/wenxinMenu/keywork',
  	    name: '关键字管理',
  	    component: () => import('@/views/wenxinMenu/keywork/index.vue')
  	  },
  	
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@views/login.vue')
  }
]

// 异步挂载的路由
// 动态需要根据权限加载的路由表
const asyncRouterMap = [
  {
    path: '/permission',
    component: Home,
    name: '权限测试',
    meta: { role: ['admin', 'super_editor'] }, // 页面需要的权限
    children: [
      {
        path: 'index',
        component: Home,
        name: '权限测试页',
        meta: { role: ['admin', 'super_editor'] } // 页面需要的权限
      }]
  },
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  asyncRouterMap
})

console.log(router)

export default router
