<template>
  <div class="pagination-container">
    <el-pagination
      :total="total"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :background="background"
      :layout="layout"
      :page-sizes="pageSizes"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50, 100]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    page: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 10
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.size
      },
      set(val) {
        this.$emit('update:size', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, size: val })
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, size: this.pageSize })
    },
    // 当列表点删除时，删除的那一页，只剩一条时，页码需要往上退一页
    resetDeletPage () {
      if (this.total % this.pageSize === 1) {
        this.currentPage = this.currentPage - 1 === 0 ? 1 : this.currentPage - 1
      }
      // 按照当前页，或者回退上一页，刷新 主列表
      this.handleCurrentChange(this.currentPage)
    }
  }
}
</script>

<style lang="scss">
.pagination-container {
  background: #fff;
  text-align: right;
  padding-top: 15px;
  .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next, .el-pagination.is-background .el-pager li{background: #F5F5F2;}
  .el-pagination.is-background .el-pager li:not(.disabled).active{color: #53402B;}
  .el-pagination.is-background .el-pager li:not(.disabled):hover{color: #53402B;}
}
</style>
