import { getOrgGroupScaleRes } from '@api/api_common'

const commonModule = {
  namespaced: true,
  state: {

    orgAreaTree: [], // 组织机构树——到片区
    orgGardenTree: [] // 组织机构树——到园所
  },
  getters: {

  },
  mutations: {
    SET_orgAreaTree (state, orgAreaTree) {
      state.orgAreaTree = orgAreaTree
    },
    SET_orgGardenTree (state, orgGardenTree) {
      state.orgGardenTree = orgGardenTree
    }
  },
  actions: {
    // 查询组织机构树，type：1/2 级别到片区，3到园所
    getOrgTree ({ commit }, type) {
      return new Promise((resolve, reject) => {
        getOrgGroupScaleRes({ type }).then(res => {
          if (type === 3) {
            commit('SET_orgGardenTree', res)
          } else {
            commit('SET_orgAreaTree', res)
          }
          resolve(res)
        })
      })
    }
  }
}

export default commonModule
