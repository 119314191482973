<template>
  <el-cascader
    ref="innerCascader"
    style="width: 100%;"
    :value="parentSelect"
    :options="options"
    :clearable="clearable"
    :collapse-tags="collapse"
    :filterable="filterable"
    :disabled="disabled"
    :placeholder="placeholder"
    :props="{
      multiple: multiple,
      checkStrictly: checkStrictly,
      emitPath: emitPath,
      value: valueVal,
      label: labelVal
    }"
    @change="handleChange"
  />
</template>

<script>
export default {
  model: {
    prop: 'parentSelect',
    event: 'modifyChange'
  },
  props: {
    parentSelect: {
      type: [Number, Array, String],
      default: null
    },
    options: {
      type: Array,
      default: null
    },
    clearable: {
      type: Boolean,
      default: true
    },
    collapse: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    checkStrictly: {
      type: Boolean,
      default: true
    },
    emitPath: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    valueVal: {
      type: String,
      default: 'value'
    },
    labelVal: {
      type: String,
      default: 'label'
    }
  },
  data() {
    return {
      selectId: ''
    }
  },
  watch: {
    parentSelect() {
      if (this.$refs.innerCascader && this.multiple === false) {
        this.$refs.innerCascader.dropDownVisible = false
      }
    }
  },
  methods: {
    handleChange(val) {
      this.$emit('modifyChange', val)
    }
  }
}
</script>

<style lang="scss" scoped></style>
