import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { showFullScreenLoading, tryHideFullScreenLoading } from '@utils/loading'
import store from '@store/index.js'
import noLoadingApi from '@utils/noLoadingApi.js'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_PAGE,
  // baseURL: 'http://39.103.146.150:8421/manager',
  // baseURL: 'http://172.18.132.159:8423',
  // baseURL: 'http://47.118.23.224:8421/manager',
  timeout: 5000000, // 请求超时时间
  withCredentials: true,
  headers: { 'Cache-Control': 'no-store' }
})

// request拦截器
service.interceptors.request.use(config => {
  // Do something before request is sent
  if (!noLoadingApi.api.includes(config.url)) {
    showFullScreenLoading()
  }
  if (store.state.token) {
    config.headers['Authorization'] = store.state.token // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(response => {
  // if (!_selectIsHide(noLoadingApi.api, response.config.url)) {
  if (!noLoadingApi.api.includes(response.config.url)) {
    tryHideFullScreenLoading()
  }
  if (response.data.code === 20000) {
    return response.data.data
  } else {
    Message.closeAll()
    Message({
      message: response.data.message,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(response.data.message)
  }
},
error => {
  if (!noLoadingApi.api.includes(error.response.data.path)) {
    tryHideFullScreenLoading()
  }
  // token失效 或 同一账号 他人登录后，强制退出
  console.log(error.response.status)
  if (error.response.status === 401) {
    // 通过开关进行控制，多次弹出的情况
    console.log(store.state.isShowErrorMessageBox)
    if (store.state.isShowErrorMessageBox) {
      MessageBox.alert('您已被登出，是否重新登录', '重新登录', {
        confirmButtonText: '重新登录',
        showClose: false,
        type: 'warning'
      }).then(() => {
        store.dispatch('LogOut').then(() => {
          // location.reload();// 为了重新实例化vue-router对象 避免bug
          store.commit('SET_isShowErrorMessageBox', true)
        })
      })
      store.commit('SET_isShowErrorMessageBox', false)
    }
    return Promise.reject(error)
  } else {
    Message.closeAll()
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
})

// 判断 返回后，接口地址是否在黑名单目录里
// function _selectIsHide (arr, url) {
//   let flag = false
//   for (const item of arr) {
//     if (url.includes(item)) {
//       flag = true
//       break
//     }
//   }
//   return flag
// }

export default service
