<template>
  <el-input
    type="textarea"
    :value="textareaVal"
    :placeholder="placeholder"
    :disabled="disabled"
    :show-word-limit="showWword"
    :autosize="{ minRows: 5, maxRows: 8 }"
    :readonly="readonly"
    :resize="resize"
    :maxlength="maxlength"
    :minlength="minlength"
    @input="handleInput"
  />
</template>

<script>
export default {
  model: {
    prop: 'textareaVal',
    event: 'modifyChange'
  },
  props: {
    textareaVal: [String, Number],
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 1000
    },
    minlength: {
      type: Number,
      default: 0
    },
    showWword: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    resize: {
      type: String,
      default: 'none'
    }
  },
  methods: {
    handleInput($event) {
      this.$emit('modifyChange', $event)
    }
  }
}
</script>
