/*
无需loading的api
*/
const api = [
  // 公共
  '/applicationForm/pendingApproval',
  
  // '/file/uploadFile',
  '/verification/fiveStartHotel',
  '/verification/holidayVerificationWithCode',
  '/verification/meetingPerCapita',
  '/verification/suppliesPriceLimit',
  '/verification/mealStandard',
  '/verification/entertainPeopleStandard',
]

export default {
  api
}
