<template>
  <el-breadcrumb separator="/" class="common-breadcrumb">
    <el-breadcrumb-item v-for="(item,index) in breadList" :key="index">{{ item }}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  props: {
    bread: {
      type: String,
      default: ''
    }
  },
  computed: {
    breadList () {
      if (this.bread) {
        return this.bread.split(',')
      } else {
        return []
      }
    }
  },
  methods: {

  }
}
</script>
<style lang="scss">
  .common-breadcrumb {
    margin-bottom: 20px;
    span{color: rgba(0, 0, 0, 0.45);}
    span:last-child .el-breadcrumb__inner{color: #53402B;}
  }
</style>
